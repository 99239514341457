import { ReactNode } from "react";
import styled from "styled-components";
import mediaQueries from "~/guidelines/Theme/mediaQueries";
import { body1, heading5 } from "~/guidelines/Typography";

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(3)};
  padding: ${({ theme }) => theme.spacing(5, 6)};
  padding-top: calc(
    var(--bottom-sheet-drag-handle-size, 0px) +
      ${({ theme }) => theme.spacing(5)}
  );
  border-bottom: 1px solid ${({ theme }) => theme.color.line.lightFirst};

  ${mediaQueries.md()} {
    padding: ${({ theme }) => theme.spacing(6, 6)};
    padding-top: calc(
      var(--bottom-sheet-drag-handle-size, 0px) +
        ${({ theme }) => theme.spacing(6)}
    );
    border-bottom: none;
  }
`;

const HeaderTitle = styled.h5`
  margin: 0;
  ${heading5};
`;

const HeaderSubTitle = styled.h6`
  margin: 0;
  ${body1};
  color: ${({ theme }) => theme.color.text.lightThird};
`;

const Header = ({
  title,
  subtitle,
}: {
  title: ReactNode;
  subtitle?: ReactNode;
}) => {
  return (
    <HeaderContainer>
      <HeaderTitle>{title}</HeaderTitle>
      <HeaderSubTitle>{subtitle}</HeaderSubTitle>
    </HeaderContainer>
  );
};

const ContentContainer = styled.div`
  padding: ${({ theme }) => theme.spacing(6)};
`;

const Content = ({ children }: { children: ReactNode }) => {
  return <ContentContainer>{children}</ContentContainer>;
};

const FooterContainer = styled.div`
  padding: ${({ theme }) => theme.spacing(5, 6)};
  border-top: 1px solid ${({ theme }) => theme.color.line.lightFirst};

  ${mediaQueries.md()} {
    padding: ${({ theme }) => theme.spacing(6, 6)};
    border-top: none;
  }
`;

const Footer = ({ children }: { children: ReactNode }) => {
  return <FooterContainer>{children}</FooterContainer>;
};

export default { Header, Content, Footer };
