import React, { ReactNode } from "react";
import { useMemo } from "react";
import styled from "styled-components";
import FadeInGrow from "~/guidelines/Animations/FadeInGrow";
import {
  Information,
  NavigationCheck,
  NotificationImportant,
} from "~/guidelines/Icon";
import IconSvg from "~/guidelines/Icon/IconSvg";
import { useTheme } from "~/guidelines/Theme/useTheme";
import { caption1 } from "~/guidelines/Typography";

const Container = styled.div<{ color: string }>`
  display: flex;
  gap: 4px;
  padding-top: 4px;
  ${caption1};
  color: ${({ color }) => color};

  & > ${IconSvg} {
    width: 16px;
    height: 16px;
  }
`;

type FeedBackProps = {
  message?: string | ReactNode;
  type?: "success" | "error" | "info";
  animated?: boolean;
};

const FeedBack = ({ message, type, animated = true }: FeedBackProps) => {
  const isVisible = useMemo(
    () => message !== undefined && type !== undefined,
    [message, type],
  );

  const theme = useTheme();

  const color = () => {
    switch (type) {
      case "success":
        return theme.color.feedback.success;
      case "error":
        return theme.color.feedback.error;
      case "info":
        return theme.color.feedback.info;
      default:
        return "none";
    }
  };

  return animated ? (
    <FadeInGrow isVisible={isVisible}>
      <Container color={color()}>
        {type === "success" ? <NavigationCheck color={color()} /> : null}
        {type === "error" ? <Information color={color()} /> : null}
        {type === "info" ? <NotificationImportant color={color()} /> : null}
        {message}
      </Container>
    </FadeInGrow>
  ) : (
    <Container color={color()}>
      {type === "success" ? <NavigationCheck color={color()} /> : null}
      {type === "error" ? <Information color={color()} /> : null}
      {type === "info" ? <NotificationImportant color={color()} /> : null}
      {message}
    </Container>
  );
};

export default FeedBack;
