import React from "react";
import IconSvg, { IconSvgProps } from "~/guidelines/Icon/IconSvg";

const NavigationCheck = ({ color }: IconSvgProps) => {
  return (
    <IconSvg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      color={color}
    >
      <path
        d="M9.02309 15.875L5.55309 12.405C5.36625 12.2178 5.1126 12.1125 4.84809 12.1125C4.58357 12.1125 4.32992 12.2178 4.14309 12.405C3.75309 12.795 3.75309 13.425 4.14309 13.815L8.32309 17.995C8.71309 18.385 9.34309 18.385 9.73309 17.995L20.3131 7.41501C20.7031 7.02501 20.7031 6.39501 20.3131 6.00501C20.1263 5.81776 19.8726 5.71252 19.6081 5.71252C19.3436 5.71252 19.0899 5.81776 18.9031 6.00501L9.02309 15.875Z"
        fill="currentColor"
      />
    </IconSvg>
  );
};

export default NavigationCheck;
