import { DestinationSpecificities } from "~/applications/OfferSearch/Domain/DestinationSpecificities";
import { ResourceVisibility } from "~/core/ResourceVisibility";
import { ImageWithMetadata } from "~/core/image";

type SEO = {
  picture: ImageWithMetadata;
  description: string;
};

type LifeInDestination = {
  description: string;
  specificities: Array<DestinationSpecificities>;
};

type DestinationPageEditorial = {
  heroBackgroundPicture: ImageWithMetadata;
  pictureGalleryDescription: string;
  lifeInDestination: LifeInDestination;
  seo: SEO;
};

export type Capsule = {
  top: string;
  bottom: string;
};

export type DestinationPage = {
  seoSlug: string;
  destination: {
    sanityId: string;
    name: string;
    images: Array<{ asset: ImageWithMetadata; caption: string }>;
  };
  visibility: ResourceVisibility;
  photo: ImageWithMetadata;
  introduction: string;
  firstBookableDate: Date | null;
  features: {
    guestCanBookARoom: boolean;
  };
  destinationPageEditorial: DestinationPageEditorial;
  capsule: Capsule | null;
};

export type DestinationMenuEntry = {
  sanityId: string;
  seoSlug: string;
  name: string;
  visibility: ResourceVisibility;
  firstBookableDate: Date | null;
  capsule: Capsule | null;
};

export const destinationToMenuEntry = (
  destination: DestinationPage,
): DestinationMenuEntry => {
  return {
    sanityId: destination.destination.sanityId,
    seoSlug: destination.seoSlug,
    name: destination.destination.name,
    visibility: destination.visibility,
    firstBookableDate: destination.firstBookableDate,
    capsule: destination.capsule,
  };
};
