import { useQuery } from "@tanstack/react-query";
import { z } from "zod";
import { DestinationPage } from "~/applications/OfferSearch/Domain/DestinationPage";
import { contentStudioClient } from "~/clients/ContentStudio";
import {
  ALL_RESOURCE_VISIBILITY,
  ResourceVisibilityPayloadSchema,
} from "~/core/ResourceVisibility";
import { SanityImageWithMetadataPayloadSchema } from "~/core/api/Sanity/asset";
import {
  SanityLocalizedFieldSchema,
  localizeField,
  localizedFieldsProjection,
} from "~/core/api/Sanity/localize";
import { buildGroqQuery } from "~/core/api/Sanity/query";
import { AVAILABLE_LOCALES } from "~/core/locale";

const getAllDestinationPageQuery = (locale: AVAILABLE_LOCALES) =>
  buildGroqQuery(`
  *[_type == 'destinationWebsite' && seoSlug.current == $seoSlug && visibility.value in $visibilities && !(_id in path('drafts.**'))] | order(seoSlug.current)
  {
    seoSlug,
    destination->{
      _id,
      name,
      gallery[]{
        asset->{
          url,
          metadata {
            lqip,
            dimensions {
              height,
              width
            },
          }
        },
        caption{ ${localizedFieldsProjection(locale)} }
      },
    },
    firstBookableDate,
    destinationPageEditorial->{
      "heroBackgroundPicture": heroBackgroundPicture.asset->{
        url,
        metadata {
          lqip,
          dimensions {
            height,
            width
          },
        },
      },
      "pictureGalleryDescription": destinationPictureGalleryDescription{ ${localizedFieldsProjection(
        locale,
      )} },
      "lifeInDestination": {
        "description": lifeInDestinationDescription{ ${localizedFieldsProjection(
          locale,
        )} },
        specificities[]{
          "illustration": illlustration.asset->{
            url,
            metadata {
              lqip,
              dimensions {
                height,
                width
              },
            },
          },
          title{ ${localizedFieldsProjection(locale)} },
          description{ ${localizedFieldsProjection(locale)} },
        },
      },
      "seo": {
        "picture": opengraphImage.asset->{
          url,
          metadata {
            lqip,
            dimensions {
              height,
              width
            },
          },
        },
        "description": opengraphDescription,
      },
    },
    visibility,
    photo {
      asset->{
        url,
        metadata {
          lqip,
          dimensions {
            height,
            width
          },
        },
      },
    },
    introduction{ ${localizedFieldsProjection(locale)} },
    capsule,
  }
`);

export const DestinationPagePayloadSchema = z.object({
  seoSlug: z.object({
    current: z.string(),
  }),
  destination: z.object({
    _id: z.string(),
    name: z.string(),
    gallery: z
      .array(
        z.object({
          asset: SanityImageWithMetadataPayloadSchema,
          caption: SanityLocalizedFieldSchema,
        }),
      )
      .nullable()
      .transform((gallery) => gallery ?? []),
  }),
  firstBookableDate: z.string().nullable(),
  destinationPageEditorial: z.object({
    heroBackgroundPicture: SanityImageWithMetadataPayloadSchema,
    pictureGalleryDescription: SanityLocalizedFieldSchema,
    lifeInDestination: z.object({
      description: SanityLocalizedFieldSchema,
      specificities: z.array(
        z.object({
          illustration: SanityImageWithMetadataPayloadSchema,
          title: SanityLocalizedFieldSchema,
          description: SanityLocalizedFieldSchema,
        }),
      ),
    }),
    seo: z.object({
      picture: SanityImageWithMetadataPayloadSchema,
      description: SanityLocalizedFieldSchema,
    }),
  }),
  visibility: ResourceVisibilityPayloadSchema,
  photo: z.object({
    asset: SanityImageWithMetadataPayloadSchema,
  }),
  introduction: SanityLocalizedFieldSchema,
  capsule: z.object({
    topColor: z.object({ value: z.string() }),
    bottomColor: z.object({ value: z.string() }),
  }),
});

const DestinationPagesPayloadSchema = z.array(DestinationPagePayloadSchema);

type FetchDestinationPageQuery = {
  locale: AVAILABLE_LOCALES;
  destinationSlug: string;
};

const fetchDestinationPageKey = (
  query: FetchDestinationPageQuery,
): ["offer-search-destination-page", FetchDestinationPageQuery] => [
  "offer-search-destination-page",
  query,
];

export async function fetchDestinationPage({
  destinationSlug,
  locale,
}: FetchDestinationPageQuery): Promise<DestinationPage | undefined> {
  const response = await contentStudioClient.fetch(
    getAllDestinationPageQuery(locale),
    {
      seoSlug: destinationSlug,
      visibilities: ALL_RESOURCE_VISIBILITY,
    },
  );

  const destinationPagePayload = DestinationPagesPayloadSchema.parse(response);

  if (destinationPagePayload.length !== 1) {
    return undefined;
  }

  const currentDestinationPagePayload = destinationPagePayload[0];

  return {
    seoSlug: currentDestinationPagePayload.seoSlug.current,
    destination: {
      sanityId: currentDestinationPagePayload.destination._id,
      name: currentDestinationPagePayload.destination.name,
      images: currentDestinationPagePayload.destination.gallery.map(
        (oneImage) => ({
          ...oneImage,
          caption: localizeField(oneImage.caption, locale),
        }),
      ),
    },
    photo: {
      url: currentDestinationPagePayload.photo.asset.url,
      metadata: {
        lqip: currentDestinationPagePayload.photo.asset.metadata.lqip ?? null,
        dimensions: {
          width:
            currentDestinationPagePayload.photo.asset.metadata.dimensions.width,
          height:
            currentDestinationPagePayload.photo.asset.metadata.dimensions
              .height,
        },
      },
    },
    introduction: localizeField(
      currentDestinationPagePayload.introduction,
      locale,
    ),
    firstBookableDate: currentDestinationPagePayload.firstBookableDate
      ? new Date(currentDestinationPagePayload.firstBookableDate)
      : null,
    visibility: currentDestinationPagePayload.visibility.value,
    destinationPageEditorial: {
      heroBackgroundPicture:
        currentDestinationPagePayload.destinationPageEditorial
          .heroBackgroundPicture,
      pictureGalleryDescription: localizeField(
        currentDestinationPagePayload.destinationPageEditorial
          .pictureGalleryDescription,
        locale,
      ),
      lifeInDestination: {
        description: localizeField(
          currentDestinationPagePayload.destinationPageEditorial
            .lifeInDestination.description,
          locale,
        ),
        specificities:
          currentDestinationPagePayload.destinationPageEditorial.lifeInDestination.specificities.map(
            (oneSpecificity) => ({
              picture: oneSpecificity.illustration,
              title: localizeField(oneSpecificity.title, locale),
              description: localizeField(oneSpecificity.description, locale),
            }),
          ),
      },
      seo: {
        picture:
          currentDestinationPagePayload.destinationPageEditorial.seo.picture,
        description: localizeField(
          currentDestinationPagePayload.destinationPageEditorial.seo
            .description,
          locale,
        ),
      },
    },
    features: {
      guestCanBookARoom:
        currentDestinationPagePayload.firstBookableDate !== null,
    },
    capsule: {
      top: currentDestinationPagePayload.capsule.topColor.value,
      bottom: currentDestinationPagePayload.capsule.bottomColor.value,
    },
  };
}

export const useDestinationPage = (
  query: FetchDestinationPageQuery,
  initialData?: DestinationPage,
): { destinationPage: DestinationPage | null } => {
  const { data: destinationPage = null } = useQuery(
    fetchDestinationPageKey(query),
    () => fetchDestinationPage(query),
    {
      initialData,
    },
  );

  return { destinationPage };
};
