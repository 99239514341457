import { useQuery } from "@tanstack/react-query";
import { z } from "zod";
import { DestinationPage } from "~/applications/OfferSearch/Domain/DestinationPage";
import { contentStudioClient } from "~/clients/ContentStudio";
import {
  ALL_RESOURCE_VISIBILITY,
  ResourceVisibility,
} from "~/core/ResourceVisibility";
import {
  localizeField,
  localizedFieldsProjection,
} from "~/core/api/Sanity/localize";
import { buildGroqQuery } from "~/core/api/Sanity/query";
import { AVAILABLE_LOCALES } from "~/core/locale";
import { DestinationPagePayloadSchema } from "./useDestinationPage";

const getAllDestinationPagesQuery = (locale: AVAILABLE_LOCALES) =>
  buildGroqQuery(`
  *[_type == 'destinationWebsite' && visibility.value in $visibilities && !(_id in path('drafts.**'))] | order(seoSlug.current)
  {
    seoSlug,
    destination->{
      _id,
      name,
      gallery[]{
        asset->{
          url,
          metadata {
            lqip,
            dimensions {
              height,
              width
            },
          }
        },
        caption{ ${localizedFieldsProjection(locale)} }
      },
    },
    firstBookableDate,
    destinationPageEditorial->{
      "heroBackgroundPicture": heroBackgroundPicture.asset->{
        url,
        metadata {
          lqip,
          dimensions {
            height,
            width
          },
        },
      },
      "pictureGalleryDescription": destinationPictureGalleryDescription{ ${localizedFieldsProjection(
        locale,
      )} },
      "lifeInDestination": {
        "description": lifeInDestinationDescription{ ${localizedFieldsProjection(
          locale,
        )} },
        specificities[]{
          "illustration": illlustration.asset->{
            url,
            metadata {
              lqip,
              dimensions {
                height,
                width
              },
            },
          },
          title{ ${localizedFieldsProjection(locale)} },
          description{ ${localizedFieldsProjection(locale)} },
        },
      },
      "seo": {
        "picture": opengraphImage.asset->{
          url,
          metadata {
            lqip,
            dimensions {
              height,
              width
            },
          },
        },
        "description": opengraphDescription,
      },
    },
    visibility,
    photo {
      asset->{
        url,
        metadata {
          lqip,
          dimensions {
            height,
            width
          },
        },
      },
    },
    introduction{ ${localizedFieldsProjection(locale)} },
    capsule,
  }
`);

const AllDestinationPagesPayloadSchema = z.array(DestinationPagePayloadSchema);

type FetchDestinationPagesQuery = {
  locale: AVAILABLE_LOCALES;
  visibilities?: Array<ResourceVisibility>;
};

export async function fetchDestinationPages({
  locale,
  visibilities = ALL_RESOURCE_VISIBILITY,
}: FetchDestinationPagesQuery): Promise<Array<DestinationPage>> {
  const response = await contentStudioClient.fetch(
    getAllDestinationPagesQuery(locale),
    { visibilities },
  );

  const allDestinationPagesPayload =
    AllDestinationPagesPayloadSchema.parse(response);

  return allDestinationPagesPayload.map((oneDestinationPagePayload) => {
    return {
      seoSlug: oneDestinationPagePayload.seoSlug.current,
      destination: {
        sanityId: oneDestinationPagePayload.destination._id,
        name: oneDestinationPagePayload.destination.name,
        images: oneDestinationPagePayload.destination.gallery.map(
          (oneImage) => ({
            ...oneImage,
            caption: localizeField(oneImage?.caption, locale),
          }),
        ),
      },
      photo: {
        url: oneDestinationPagePayload.photo.asset.url,
        metadata: {
          lqip: oneDestinationPagePayload.photo.asset.metadata.lqip ?? null,
          dimensions: {
            width:
              oneDestinationPagePayload.photo.asset.metadata.dimensions.width,
            height:
              oneDestinationPagePayload.photo.asset.metadata.dimensions.height,
          },
        },
      },
      introduction: localizeField(
        oneDestinationPagePayload.introduction,
        locale,
      ),
      firstBookableDate: oneDestinationPagePayload.firstBookableDate
        ? new Date(oneDestinationPagePayload.firstBookableDate)
        : null,
      destinationPageEditorial: {
        heroBackgroundPicture:
          oneDestinationPagePayload.destinationPageEditorial
            .heroBackgroundPicture,
        pictureGalleryDescription: localizeField(
          oneDestinationPagePayload.destinationPageEditorial
            .pictureGalleryDescription,
          locale,
        ),
        lifeInDestination: {
          description: localizeField(
            oneDestinationPagePayload.destinationPageEditorial.lifeInDestination
              .description,
            locale,
          ),
          specificities:
            oneDestinationPagePayload.destinationPageEditorial.lifeInDestination.specificities.map(
              (oneSpecificity) => ({
                picture: oneSpecificity.illustration,
                title: localizeField(oneSpecificity.title, locale),
                description: localizeField(oneSpecificity.description, locale),
              }),
            ),
        },
        seo: {
          picture:
            oneDestinationPagePayload.destinationPageEditorial.seo.picture,
          description: localizeField(
            oneDestinationPagePayload.destinationPageEditorial.seo.description,
            locale,
          ),
        },
      },
      visibility: oneDestinationPagePayload.visibility.value,
      features: {
        guestCanBookARoom: oneDestinationPagePayload.firstBookableDate !== null,
      },
      capsule: {
        top: oneDestinationPagePayload.capsule.topColor.value,
        bottom: oneDestinationPagePayload.capsule.bottomColor.value,
      },
    };
  });
}

export const useDestinationPages = (
  query: FetchDestinationPagesQuery,
  initialData?: Array<DestinationPage>,
): { destinationPages: Array<DestinationPage> | null } => {
  const { data: destinationPages = null } = useQuery(
    ["offer-search-destinations-pages"],
    () => fetchDestinationPages(query),
    {
      initialData,
    },
  );

  return { destinationPages };
};
