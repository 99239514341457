import { AriaButtonProps } from "@react-types/button";
import React, { ReactNode, RefObject, useRef } from "react";
import { useButton } from "react-aria";
import styled from "styled-components";
import { KeyboardArrowDown } from "~/guidelines/Icon";
import { body1Bold, caption1 } from "~/guidelines/Typography";

const ButtonAsFakeInput = styled.button`
  appearance: none;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(3)};
  width: 100%;
  margin: 0;
  padding: ${({ theme }) => theme.spacing(4, 6)};
  border: none;
  background: ${({ theme }) => theme.color.background.lightFirst};
  color: ${({ theme }) => theme.color.text.lightTertiary};
  cursor: pointer;
  text-align: start;
`;

const Column = styled.span`
  display: inline-flex;
  flex-direction: column;
  justify-content: stretch;
  gap: ${({ theme }) => theme.spacing(0)};
  min-width: calc(100% - 24px); /* Preserve the arrow down area */
`;

const Label = styled.span`
  ${caption1};
`;

const Value = styled.span`
  ${body1Bold};
  color: ${({ theme }) => theme.color.text.lightFirst};
`;

export type FakeSelectTriggerProps = AriaButtonProps<"button"> & {
  label: string;
  children?: ReactNode;
  className?: string;
};

export const FakeSelectTrigger = React.forwardRef<
  HTMLButtonElement,
  FakeSelectTriggerProps
>(function FakeInputForwardRef(
  { label, children, className, ...ariaButtonProps },
  forwardedRef,
) {
  const defaultRef = useRef();
  const ref = (forwardedRef ?? defaultRef) as RefObject<HTMLButtonElement>;

  const { buttonProps } = useButton(
    { ...ariaButtonProps, "aria-label": label },
    ref,
  );

  return (
    <ButtonAsFakeInput {...buttonProps} className={className} ref={ref}>
      <Column>
        <Label>{label}</Label>
        <Value>{children}</Value>
      </Column>
      <KeyboardArrowDown aria-hidden="true" />
    </ButtonAsFakeInput>
  );
});
