import React from "react";
import styled from "styled-components";
import LottiePlayer from "~/components/Animation/LottiePlayer";
import PulseLoader from "~/guidelines/Animations/PulseLoader";

const CenteredLoader = styled.div`
  padding: 5em 0;
  text-align: center;
`;

const SmallCenteredLoader = styled.div`
  padding: 0;
  text-align: center;
  width: 100%;
`;

const LottieLoaderContainer = styled.div`
  max-width: 300px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 50svh;
`;

export const CenteredPulseLoader = ({ color }: { color?: string }) => (
  <CenteredLoader data-testid="loader">
    <PulseLoader width="200px" color={color} />
  </CenteredLoader>
);

export const SmallCenteredPulseLoader = ({ color }: { color?: string }) => (
  <SmallCenteredLoader data-testid="loader">
    <PulseLoader width="100px" color={color} />
  </SmallCenteredLoader>
);

export const LottieLoader = () => {
  return (
    <LottieLoaderContainer>
      <LottiePlayer
        lottieLoader={() => import("~/guidelines/Loaders/loader.lottie.json")}
        autoplay={true}
        loop={true}
        play={true}
      />
    </LottieLoaderContainer>
  );
};

export const LottieOfferLoader = () => {
  return (
    <LottieLoaderContainer>
      <LottiePlayer
        lottieLoader={() =>
          import("~/guidelines/Loaders/loader-encoremieux.lottie.json")
        }
        autoplay={true}
        loop={true}
        play={true}
      />
    </LottieLoaderContainer>
  );
};
