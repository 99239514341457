import styled from "styled-components";
import { Capsule } from "~/applications/OfferSearch/Domain/DestinationPage";
import { Stack } from "~/guidelines/Flex";
import mediaQueries from "~/guidelines/Theme/mediaQueries";

const Container = styled(Stack)`
  box-sizing: border-box;
  width: 12px;
  height: 18px;
  border: 1px solid ${({ theme }) => theme.color.line.lightPrimary};
  flex-shrink: 0;

  ${mediaQueries.md()} {
    width: 18px;
    height: 24px;
  }
`;

const Top = styled.div<{ color: string }>`
  background-color: ${(props) => props.color};
  flex: 1;
  border-bottom: 1px solid ${({ theme }) => theme.color.line.lightPrimary};
`;

const Bottom = styled.div<{ color: string }>`
  background-color: ${(props) => props.color};
  flex: 2;
`;

const CapsuleBox = ({ capsule }: { capsule: Capsule }) => {
  return (
    <Container>
      <Top color={capsule.top} />
      <Bottom color={capsule.bottom} />
    </Container>
  );
};
export default CapsuleBox;
