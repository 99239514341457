import NiceModal, { NiceModalHocProps, useModal } from "@ebay/nice-modal-react";
import { Trans, t } from "@lingui/macro";
import React, { useState } from "react";
import styled from "styled-components";
import { DestinationMenuEntry } from "~/applications/OfferSearch/Domain/DestinationPage";
import DestinationSelector from "~/applications/OfferSearch/Ui/StayRequestForm/Selector/DestinationSelector";
import { Button } from "~/guidelines/Button";
import Drawer from "~/guidelines/Drawer/Drawer";
import EngagingLayout from "~/guidelines/Drawer/Layout/EngagingLayout";
import mediaQueries from "~/guidelines/Theme/mediaQueries";

const ContentSizer = styled.div`
  height: 100%;

  ${mediaQueries.md()} {
    width: 350px;
    max-width: 350px;
  }
`;

type DestinationSelectorDrawerParameters = {
  defaultDestination?: DestinationMenuEntry;
  destinations: Array<DestinationMenuEntry>;
  onSubmit: (newDestination: DestinationMenuEntry) => void;
};

export type DestinationSelectorDrawerProps = NiceModalHocProps &
  DestinationSelectorDrawerParameters;

const DestinationSelectorDrawer =
  NiceModal.create<DestinationSelectorDrawerParameters>(
    ({ defaultDestination, destinations, onSubmit }) => {
      const modal = useModal();

      const [selectedDestination, setSelectedDestination] = useState<
        DestinationMenuEntry | undefined
      >(() => defaultDestination);

      return (
        <Drawer
          isOpen={modal.visible}
          title={t`Select a destination`}
          variant="raw"
          backgroundColor="lightFirst"
          header={
            <EngagingLayout.Header
              title={t`Select a destination`}
              subtitle={selectedDestination?.name ?? defaultDestination?.name}
            />
          }
          footer={
            <EngagingLayout.Footer>
              <Button
                size="large"
                fluid
                isDisabled={selectedDestination === undefined}
                onPress={() => {
                  if (!selectedDestination) {
                    return;
                  }

                  modal.hide();
                  onSubmit(selectedDestination);
                }}
              >
                <Trans>Confirm</Trans>
              </Button>
            </EngagingLayout.Footer>
          }
          onClose={modal.hide}
          onAfterClose={() => {
            if (!modal.keepMounted) {
              modal.remove();
            }
          }}
        >
          <EngagingLayout.Content>
            <ContentSizer>
              <DestinationSelector
                selectedDestination={defaultDestination}
                destinations={destinations}
                onChange={setSelectedDestination}
              />
            </ContentSizer>
          </EngagingLayout.Content>
        </Drawer>
      );
    },
  );

export default DestinationSelectorDrawer;
