"use client";

import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { Trans, t } from "@lingui/macro";
import dynamic from "next/dynamic";
import React, { useMemo, useRef } from "react";
import styled from "styled-components";
import { CancellationPolicyId } from "~/applications/OfferSearch/Domain/CancellationPolicyId";
import { DestinationMenuEntry } from "~/applications/OfferSearch/Domain/DestinationPage";
import { TravelPurpose } from "~/applications/OfferSearch/Domain/TravelPurpose";
import CardStayRequestForm from "~/applications/OfferSearch/Ui/StayRequestForm/CardStayRequestForm";
import {
  Guests,
  StayDates,
  useNewSearch,
} from "~/applications/OfferSearch/Ui/StayRequestForm/useSearch";
import StayRequest from "~/core/StayRequest";
import { Route } from "~/core/routes";
import { Button } from "~/guidelines/Button";
import InformativeLayout from "~/guidelines/Drawer/Layout/InformativeLayout";
import { CenteredPulseLoader } from "~/guidelines/Loaders";
import mediaQueries from "~/guidelines/Theme/mediaQueries";

const DrawerDynamic = dynamic(() => import("~/guidelines/Drawer/Drawer"), {
  ssr: false,
});

const ContentSizer = styled.div`
  height: 100%;

  ${mediaQueries.md()} {
    width: 430px;
    max-width: 430px;
  }
`;

const BookLogeLayout = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(7)};
`;

const CardsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(7)};
  padding-bottom: ${({ theme }) => theme.spacing(4)};

  ${mediaQueries.md()} {
    padding-bottom: 0;
  }
`;

export enum BookingMode {
  Loges,
}

type BookDrawerModalParameters = {
  initialDestinationSlug: string;
  initialGuests?: Guests;
  initialStayDates?: StayDates;
  initialCancellationPolicyId?: CancellationPolicyId;
  initialPromoCode?: string;
  destinations: Array<DestinationMenuEntry>;
  onSearchRoomOffer: (roomsSearch: {
    destination: DestinationMenuEntry;
    stayRequest: StayRequest;
    cancellationPolicyId: CancellationPolicyId;
  }) => void;
  offerMaxCapacity: number | undefined;
  onNewSearch?: (
    newGuests: {
      nbAdults: number;
      nbChildren: number;
      nbBabies: number;
    },
    newTravelPurpose: TravelPurpose,
  ) => Route;
};

const BookDrawerModal = NiceModal.create<BookDrawerModalParameters>(
  ({
    initialGuests = undefined,
    initialStayDates = undefined,
    initialCancellationPolicyId = undefined,
    initialPromoCode = undefined,
    initialDestinationSlug,
    destinations,
    onSearchRoomOffer,
    offerMaxCapacity,
    onNewSearch,
  }) => {
    const modal = useModal();
    const drawerContentRef = useRef(null);
    const {
      selectedDestinationSeoSlug,
      setSelectedDestinationSeoSlug,

      stayDates,
      guests,
      travelPurpose,

      setStayDates,
      setGuests,
      setTravelPurpose,

      selectedStayRequest,
      selectedCancellationPolicyId,
    } = useNewSearch({
      defaultDestinationSlug: initialDestinationSlug,
      defaultGuests: initialGuests,
      defaultStayDates: initialStayDates,
      defaultCancellationPolicyId: initialCancellationPolicyId,
      defaultPromoCode: initialPromoCode,
      destinations,
    });

    const selectedDestination = useMemo<DestinationMenuEntry>(() => {
      const selectedDestination = destinations.find(
        (oneDestination) =>
          oneDestination.seoSlug === selectedDestinationSeoSlug,
      );

      // this if will not be trigger because the same exist in useNewSearch.
      // But it's needed to clear the undefined type provided by find function
      if (!selectedDestination) {
        throw new Error(
          `Could not find the selected destination in the available destinations.
           Selected slug: ${selectedDestinationSeoSlug}.
           Available: ${destinations
             .map((oneDestination) => oneDestination.seoSlug)
             .join(",")}
          `,
        );
      }

      return selectedDestination;
    }, [destinations, selectedDestinationSeoSlug]);

    return (
      <DrawerDynamic
        detent={"content-height"}
        isOpen={modal.visible}
        variant="raw"
        backgroundColor="lightFirst"
        title={t`Book a room`}
        header={
          <InformativeLayout.Header
            title={t`Book`}
            onClose={modal.hide}
          ></InformativeLayout.Header>
        }
        footer={
          <InformativeLayout.Footer>
            <Button
              size="large"
              fluid
              onPress={() => {
                onSearchRoomOffer({
                  destination: selectedDestination,
                  stayRequest: selectedStayRequest,
                  cancellationPolicyId: selectedCancellationPolicyId,
                });
              }}
            >
              <Trans>Search</Trans>
            </Button>
          </InformativeLayout.Footer>
        }
        onClose={() => {
          modal.hide();
        }}
        onAfterClose={() => {
          if (!modal.keepMounted) {
            modal.remove();
          }
        }}
      >
        <ContentSizer ref={drawerContentRef}>
          <InformativeLayout.Content isFullWith={false} isFullHeight>
            <BookLogeLayout>
              {destinations !== null ? (
                <>
                  <CardsContainer>
                    <CardStayRequestForm
                      destination={selectedDestination}
                      destinations={destinations}
                      stayDates={stayDates}
                      guests={guests}
                      travelPurpose={travelPurpose}
                      onStayDatesChange={setStayDates}
                      onGuestsChange={setGuests}
                      onTravelPurposeChange={setTravelPurpose}
                      onDestinationChange={(
                        newDestination: DestinationMenuEntry,
                      ) => {
                        setSelectedDestinationSeoSlug(newDestination.seoSlug);
                      }}
                      offerMaxCapacity={offerMaxCapacity}
                      onNewSearch={onNewSearch}
                    />
                  </CardsContainer>
                </>
              ) : (
                <CenteredPulseLoader />
              )}
            </BookLogeLayout>
          </InformativeLayout.Content>
        </ContentSizer>
      </DrawerDynamic>
    );
  },
);

export default BookDrawerModal;
