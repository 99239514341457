import React, { ReactNode } from "react";
import styled from "styled-components";
import { IconOnlyButton } from "~/guidelines/Button";
import { NavigationClose } from "~/guidelines/Icon";
import mediaQueries from "~/guidelines/Theme/mediaQueries";
import { subtitle1 } from "~/guidelines/Typography";

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: stretch;
  padding: ${({ theme }) => theme.spacing(5, 8, 5, 8)};
  padding-top: calc(
    var(--bottom-sheet-drag-handle-size, 0px) +
      ${({ theme }) => theme.spacing(5)}
  );
  gap: ${({ theme }) => theme.spacing(5)};

  ${mediaQueries.md()} {
    padding: ${({ theme }) => theme.spacing(8, 8, 5, 8)};
    padding-top: calc(
      var(--bottom-sheet-drag-handle-size, 0px) +
        ${({ theme }) => theme.spacing(8)}
    );
  }
`;

const HeaderMainRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-bottom: ${({ theme }) => theme.spacing(4)};
  border-bottom: 1px solid ${({ theme }) => theme.color.line.lightFirst};
`;

const Title = styled.div`
  ${subtitle1};
  color: ${({ theme }) => theme.color.text.lightTertiary};
`;

const Actions = styled.div``;

const Header = ({
  title,
  onClose,
  children,
}: {
  title: ReactNode;
  children?: ReactNode;
  onClose: () => void;
}) => {
  return (
    <HeaderContainer>
      <HeaderMainRow>
        <Title>{title}</Title>
        <Actions>
          <IconOnlyButton
            kind="tertiary"
            onPress={onClose}
            icon={<NavigationClose />}
          />
        </Actions>
      </HeaderMainRow>
      {children}
    </HeaderContainer>
  );
};

const ContentContainer = styled.div<{
  $isFullWidth: boolean;
  $isFullHeight: boolean;
}>`
  height: ${({ $isFullHeight }) => ($isFullHeight ? "100%" : "unset")};
  padding: ${({ theme, $isFullWidth }) =>
    theme.spacing(!$isFullWidth ? 6 : 0, !$isFullWidth ? 7 : 0)};
`;

type ContentProps = {
  isFullWith?: boolean;
  isFullHeight?: boolean;
  children: ReactNode;
};

const Content = React.forwardRef<HTMLDivElement, ContentProps>(
  function ContentWithForwardedRef(
    { isFullWith = false, isFullHeight = false, children },
    forwardedRef,
  ) {
    return (
      <ContentContainer
        ref={forwardedRef}
        $isFullWidth={isFullWith}
        $isFullHeight={isFullHeight}
      >
        {children}
      </ContentContainer>
    );
  },
);

const FooterContainer = styled.div`
  padding: ${({ theme }) => theme.spacing(5, 7)};
  border-top: 1px solid ${({ theme }) => theme.color.line.lightFirst};

  ${mediaQueries.md()} {
    padding: ${({ theme }) => theme.spacing(6, 7)};
    border-top: none;
  }
`;

const Footer = ({ children }: { children: ReactNode }) => {
  return <FooterContainer>{children}</FooterContainer>;
};

export default { Header, Content, Footer };
