import { useButton } from "@react-aria/button";
import { useDialog } from "@react-aria/dialog";
import { FocusScope } from "@react-aria/focus";
import {
  DismissButton,
  useModal,
  useOverlay,
  useOverlayPosition,
  useOverlayTrigger,
} from "@react-aria/overlays";
import { mergeProps } from "@react-aria/utils";
import { VisuallyHidden } from "@react-aria/visually-hidden";
import {
  OverlayTriggerState,
  useOverlayTriggerState,
} from "@react-stately/overlays";
import { MenuTriggerProps } from "@react-types/menu";
import React, {
  ButtonHTMLAttributes,
  HTMLAttributes,
  MutableRefObject,
  ReactNode,
  RefObject,
  useRef,
} from "react";
import styled from "styled-components";
import BottomSheet from "~/guidelines/Drawer/BottomSheet";
import { useIsMdScreen } from "~/guidelines/Theme/mediaQueries";
import { heading5 } from "~/guidelines/Typography";

type Variant = "light" | "dark";

const MenuPopOver = styled.div<{ variant: Variant }>`
  max-height: 80vh;

  background-color: ${({ theme, variant }) =>
    variant == "dark"
      ? theme.color.background.darkFirst
      : theme.color.background.lightFirst};
  border-radius: 8px;
  padding: ${({ theme }) => theme.spacing(6, 0)};
  box-shadow: ${({ theme }) => theme.shadow.navigation};
`;

const MenuModal = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.color.background.lightFirst};
  padding: ${({ theme }) => theme.spacing(6)};
`;

const Title = styled.h3`
  ${heading5};
`;

const Content = styled.div`
  padding: ${({ theme }) => theme.spacing(0, 6)};
  overflow-y: auto;
  width: 100%;
  max-height: calc(80vh - 84px);
  display: flex;
`;

const Footer = styled.div`
  padding: ${({ theme }) => theme.spacing(0, 6)};
  width: 100%;
  display: flex;
  padding-top: ${({ theme }) => theme.spacing(3)};
`;

export const useMenuState = (): OverlayTriggerState => {
  const state = useOverlayTriggerState({ defaultOpen: false });

  return state;
};

export type MenuProps = MenuTriggerProps & {
  state: OverlayTriggerState;
  title: string;
  triggerButtonRender: (
    triggerButtonProps: ButtonHTMLAttributes<HTMLButtonElement>,
    triggerButtonRef: MutableRefObject<HTMLButtonElement>,
  ) => ReactNode;
  children: ReactNode;
  footer?: ReactNode;
  variant?: Variant;
};

const Menu = ({
  state,
  title,
  triggerButtonRender,
  children,
  footer,
  variant = "light",
}: MenuProps) => {
  const isMdScreen = useIsMdScreen();

  const triggerRef =
    useRef<HTMLButtonElement>() as RefObject<HTMLButtonElement>;
  const overlayRef = useRef<HTMLDivElement>() as RefObject<HTMLDivElement>;

  const { triggerProps, overlayProps } = useOverlayTrigger(
    { type: "dialog" },
    state,
    triggerRef,
  );

  const { overlayProps: positionProps } = useOverlayPosition({
    targetRef: triggerRef,
    overlayRef: overlayRef,
    placement: "bottom end",
    offset: 8,
    containerPadding: 8,
    isOpen: state.isOpen,
  });

  const { buttonProps: triggerButtonProps } = useButton(
    {
      ...triggerProps,
      onPress: () => state.open(),
    },
    triggerRef as RefObject<HTMLButtonElement>,
  );

  return (
    <div style={{ position: "relative", display: "inline-block" }}>
      {triggerButtonRender(
        triggerButtonProps,
        triggerRef as MutableRefObject<HTMLButtonElement>,
      )}

      {isMdScreen ? (
        state.isOpen && (
          <Popover
            {...overlayProps}
            {...positionProps}
            ref={overlayRef}
            title={title}
            isOpen={state.isOpen}
            onClose={state.close}
            footer={footer}
            variant={variant}
          >
            {children}
          </Popover>
        )
      ) : (
        <BottomSheet
          isDismissable
          isOpen={state.isOpen}
          onClose={state.close}
          onAfterClose={() => null}
          footer={footer}
        >
          <MenuModal>
            <Title>{title}</Title>

            {children}
          </MenuModal>
        </BottomSheet>
      )}
    </div>
  );
};

export default Menu;

type PopoverProps = HTMLAttributes<HTMLDivElement> & {
  isOpen: boolean;
  title: string;
  onClose: () => void;
  children: ReactNode;
  footer: ReactNode;
  variant: Variant;
};

const Popover = React.forwardRef<HTMLDivElement, PopoverProps>(
  function PopoverWithRef(
    { title, children, footer, isOpen, onClose, style, variant, ...otherProps },
    ref,
  ) {
    const { overlayProps } = useOverlay(
      {
        onClose,
        isOpen,
        isDismissable: true,
      },
      ref as RefObject<HTMLDivElement>,
    );

    const { modalProps } = useModal();

    const { dialogProps, titleProps } = useDialog(
      {},
      ref as RefObject<HTMLDivElement>,
    );

    return (
      <FocusScope>
        <div
          {...mergeProps(overlayProps, dialogProps, otherProps, modalProps)}
          ref={ref}
          style={{ padding: 0, margin: 0, ...style }}
        >
          <DismissButton onDismiss={onClose} />

          <VisuallyHidden>
            <Title {...titleProps}>{title}</Title>
          </VisuallyHidden>

          <MenuPopOver variant={variant}>
            <Content>{children}</Content>
            <Footer>{footer}</Footer>
          </MenuPopOver>
        </div>
      </FocusScope>
    );
  },
);
