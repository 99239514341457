import React from "react";
import IconSvg, { IconSvgProps } from "~/guidelines/Icon/IconSvg";

const NotificationImportant = ({ color }: IconSvgProps) => {
  return (
    <IconSvg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      color={color}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.2282 16.75L20.5182 18.04C21.1482 18.67 20.7082 19.75 19.8082 19.75H4.6382C3.7482 19.75 3.3082 18.67 3.9382 18.04L5.2282 16.75V10.75C5.2282 7.4 7.5882 4.6 10.7282 3.92V2.75C10.7282 1.92 11.3982 1.25 12.2282 1.25C13.0582 1.25 13.7282 1.92 13.7282 2.75V3.92C16.8682 4.6 19.2282 7.4 19.2282 10.75V16.75ZM14.2182 20.76C14.2182 21.86 13.3282 22.75 12.2282 22.75C11.1282 22.75 10.2382 21.86 10.2382 20.76H14.2182ZM11.2282 15.75V13.75H13.2282V15.75H11.2282ZM12.2282 11.75C12.7782 11.75 13.2282 11.3 13.2282 10.75V8.75C13.2282 8.2 12.7782 7.75 12.2282 7.75C11.6782 7.75 11.2282 8.2 11.2282 8.75V10.75C11.2282 11.3 11.6782 11.75 12.2282 11.75Z"
        fill="currentColor"
      />
    </IconSvg>
  );
};

export default NotificationImportant;
