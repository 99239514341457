import React from "react";
import IconSvg, { IconSvgProps } from "~/guidelines/Icon/IconSvg";

const Information = ({
  color,
  isFilled = false,
}: IconSvgProps & { isFilled?: boolean }) => {
  return (
    <IconSvg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      color={color}
    >
      {isFilled ? (
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.228 2C6.70803 2 2.22803 6.48 2.22803 12C2.22803 17.52 6.70803 22 12.228 22C17.748 22 22.228 17.52 22.228 12C22.228 6.48 17.748 2 12.228 2ZM12.228 17C11.678 17 11.228 16.55 11.228 16V12C11.228 11.45 11.678 11 12.228 11C12.778 11 13.228 11.45 13.228 12V16C13.228 16.55 12.778 17 12.228 17ZM11.228 9H13.228V7H11.228V9Z"
          fill="currentColor"
        />
      ) : (
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.228 2C6.70803 2 2.22803 6.48 2.22803 12C2.22803 17.52 6.70803 22 12.228 22C17.748 22 22.228 17.52 22.228 12C22.228 6.48 17.748 2 12.228 2ZM11.228 7V9H13.228V7H11.228ZM13.228 16C13.228 16.55 12.778 17 12.228 17C11.678 17 11.228 16.55 11.228 16V12C11.228 11.45 11.678 11 12.228 11C12.778 11 13.228 11.45 13.228 12V16ZM4.22803 12C4.22803 16.41 7.81803 20 12.228 20C16.638 20 20.228 16.41 20.228 12C20.228 7.59 16.638 4 12.228 4C7.81803 4 4.22803 7.59 4.22803 12Z"
          fill="currentColor"
        />
      )}
    </IconSvg>
  );
};

export default Information;
