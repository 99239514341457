import { Trans, t } from "@lingui/macro";
import React, { useState } from "react";
import { OverlayTriggerState } from "react-stately";
import styled from "styled-components";
import Menu, {
  useMenuState,
} from "~/applications/OfferSearch/Ui/StayRequestForm/Menu";
import DatesSelector from "~/applications/OfferSearch/Ui/StayRequestForm/Selector/DatesSelector";
import Selector from "~/applications/OfferSearch/Ui/StayRequestForm/Selector/Selector";
import TextualTrigger from "~/applications/OfferSearch/Ui/StayRequestForm/Trigger/TextualTrigger";
import { useLocale } from "~/core/useLocale";
import { Button } from "~/guidelines/Button";
import { useIsMdScreen } from "~/guidelines/Theme/mediaQueries";
import { body1 } from "~/guidelines/Typography";

const NoDate = styled.div`
  ${body1};
  color: ${({ theme }) => theme.color.text.lightThird};
  text-transform: lowercase;
`;

const DatesMenuWithTextualTrigger = ({
  firstAvailableDate,
  dates,
  onSubmit,
}: {
  firstAvailableDate: Date | null;
  dates:
    | {
        checkIn: Date;
        checkOut: Date;
      }
    | undefined;
  onSubmit: (
    newDates: { checkIn: Date; checkOut: Date },
    menuState: OverlayTriggerState,
  ) => void;
}) => {
  const locale = useLocale();

  const state = useMenuState();

  const isMdScreen = useIsMdScreen();

  const [selectedDate, setSelectedDate] = useState<{
    dates?: { checkIn: Date; checkOut: Date };
    haveChanged: boolean;
  }>(() => ({
    dates: dates,
    haveChanged: false,
  }));

  return (
    <Menu
      state={state}
      title={t`Select the dates`}
      triggerButtonRender={(triggerButtonProps, triggerButtonRef) => (
        <TextualTrigger {...triggerButtonProps} ref={triggerButtonRef}>
          {dates ? (
            t`${dates.checkIn.toLocaleDateString(locale, {
              weekday: "short",
              day: "2-digit",
              month: "short",
            })} to ${dates.checkOut.toLocaleDateString(locale, {
              weekday: "short",
              day: "2-digit",
              month: "short",
            })}`
          ) : (
            <NoDate>
              <Trans>Specify dates</Trans>
            </NoDate>
          )}
        </TextualTrigger>
      )}
      footer={
        onSubmit !== undefined ? (
          <Selector.Actions>
            <Button
              kind="primary"
              isDisabled={!selectedDate.dates && !selectedDate.haveChanged}
              onPress={() => {
                if (!selectedDate.dates) {
                  return;
                }

                onSubmit(selectedDate.dates, state);
              }}
            >
              <Trans>Confirm</Trans>
            </Button>
          </Selector.Actions>
        ) : undefined
      }
    >
      <DatesSelector
        firstAvailableDate={firstAvailableDate}
        orientation={isMdScreen ? "horizontal" : "vertical"}
        selectedDate={selectedDate.dates}
        onChange={(newDates: { checkIn: Date; checkOut: Date }) =>
          setSelectedDate({ dates: newDates, haveChanged: true })
        }
        navigationKind="button"
      />
    </Menu>
  );
};

export default DatesMenuWithTextualTrigger;
