import useComponentSize from "@rehooks/component-size";
import { motion, useMotionValue, useTransform } from "framer-motion";
import React, { useEffect, useRef } from "react";

const FadeInGrow = ({
  isVisible,
  children,
}: {
  isVisible: boolean;
  children: React.ReactNode;
}) => {
  const childrenContainerRef = useRef(null);
  const height = useMotionValue(0);
  const y = useMotionValue(-10);

  const componentSize = useComponentSize(childrenContainerRef);

  useEffect(() => {
    height.set(componentSize.height);
  }, [componentSize, height]);

  const heightAnimation = useTransform(y, [0, -10], [0, height.get()]);

  return (
    <motion.div
      animate={{
        opacity: isVisible ? 1 : 0,
        y: isVisible ? 0 : -10,
        height: isVisible ? heightAnimation.get() : 0,
      }}
      transition={{ duration: 0.3 }}
      style={{
        overflow: "hidden",
        position: "relative",
      }}
    >
      {isVisible ? (
        children
      ) : (
        <div
          ref={childrenContainerRef}
          style={{
            visibility: "hidden",
            position: "absolute",
            left: "-99999px",
            top: "-99999px",
            width: "100%",
          }}
          aria-hidden
        >
          {children}
        </div>
      )}
    </motion.div>
  );
};

export default FadeInGrow;
