import { Plural, Trans, t } from "@lingui/macro";
import React, { useState } from "react";
import { OverlayTriggerState } from "react-stately";
import styled from "styled-components";
import { TravelPurpose } from "~/applications/Checkout/Domain/TravelPurpose";
import Menu, {
  useMenuState,
} from "~/applications/OfferSearch/Ui/StayRequestForm/Menu";
import GuestsSelector from "~/applications/OfferSearch/Ui/StayRequestForm/Selector/GuestsSelector";
import Selector from "~/applications/OfferSearch/Ui/StayRequestForm/Selector/Selector";
import TextualTrigger from "~/applications/OfferSearch/Ui/StayRequestForm/Trigger/TextualTrigger";
import { formatGuestsAsNaturalString } from "~/components/GuestsFormatter";
import { Route } from "~/core/routes";
import { Button } from "~/guidelines/Button";
import FeedBack from "~/guidelines/Form/FeedBack";
import Link from "~/guidelines/Link";
import { body1 } from "~/guidelines/Typography";

const NoGuest = styled.div`
  ${body1};
  color: ${({ theme }) => theme.color.text.lightThird};
  text-transform: lowercase;
`;

type Guests = {
  nbAdults: number;
  nbChildren: number;
  nbBabies: number;
};

const GuestsMenuWithTextualTrigger = ({
  defaultGuests,
  defaultTravelPurpose,
  offerMaxCapacity,
  onSubmit,
  onNewSearch,
}: {
  defaultGuests: Guests | undefined;
  defaultTravelPurpose: TravelPurpose | undefined;
  offerMaxCapacity: number | undefined;
  onSubmit: (
    newGuests: Guests,
    newTravelPurpose: TravelPurpose,
    state: OverlayTriggerState,
  ) => void;
  onNewSearch?: (
    newGuests: {
      nbAdults: number;
      nbChildren: number;
      nbBabies: number;
    },
    newTravelPurpose: TravelPurpose,
  ) => Route;
}) => {
  const state = useMenuState();

  const [stayInformations, setStayInformations] = useState<{
    guests: Guests;
    travelPurpose: TravelPurpose;
    haveChange: boolean;
  }>(() => {
    return {
      guests: defaultGuests ?? {
        nbAdults: 1,
        nbChildren: 0,
        nbBabies: 0,
      },
      travelPurpose: defaultTravelPurpose ?? TravelPurpose.LEISURE,
      haveChange: false,
    };
  });

  const actualNbOfGuest =
    stayInformations.guests.nbAdults + stayInformations.guests.nbChildren;

  const isOfferMaxCapacityExceeded =
    offerMaxCapacity !== undefined ? actualNbOfGuest > offerMaxCapacity : false;

  /* We need div this to help the Feedback component to calculate its width */
  const outOfCapacityFeedback = (
    <div style={{ position: "relative" }}>
      <FeedBack
        message={
          isOfferMaxCapacityExceeded ? (
            <Selector.ErrorMessageContainer>
              <Trans>
                Sorry, this offer is limited to a maximum of{" "}
                <Plural
                  value={offerMaxCapacity}
                  one="# guest"
                  other="# guests"
                />
                .
              </Trans>
              {onNewSearch !== undefined ? (
                <>
                  <br />
                  <Link
                    kind="no-color"
                    href={onNewSearch(
                      stayInformations.guests,
                      stayInformations.travelPurpose,
                    )}
                  >
                    <Trans>
                      Do a new search for{" "}
                      <Plural
                        value={actualNbOfGuest}
                        one="# guest"
                        other="# guests"
                      />
                      .
                    </Trans>
                  </Link>
                </>
              ) : undefined}
            </Selector.ErrorMessageContainer>
          ) : undefined
        }
        type={isOfferMaxCapacityExceeded ? "error" : undefined}
      />
    </div>
  );

  return (
    <Menu
      state={state}
      title={t`Select the guests`}
      triggerButtonRender={(triggerButtonProps, triggerButtonRef) => (
        <TextualTrigger {...triggerButtonProps} ref={triggerButtonRef}>
          {defaultGuests ? (
            formatGuestsAsNaturalString(
              defaultGuests.nbAdults,
              defaultGuests.nbChildren,
              defaultGuests.nbBabies,
            )
          ) : (
            <NoGuest>
              <Trans>Specify guests</Trans>
            </NoGuest>
          )}
        </TextualTrigger>
      )}
      footer={
        onSubmit !== undefined ? (
          <Selector.Footer>
            {outOfCapacityFeedback}
            <Selector.Actions>
              <Button
                kind="primary"
                isDisabled={
                  !stayInformations.haveChange || isOfferMaxCapacityExceeded
                }
                onPress={() => {
                  onSubmit(
                    stayInformations.guests,
                    stayInformations.travelPurpose,
                    state,
                  );
                }}
              >
                <Trans>Confirm</Trans>
              </Button>
            </Selector.Actions>
          </Selector.Footer>
        ) : undefined
      }
    >
      <GuestsSelector
        selectedGuests={stayInformations.guests}
        selectedTravelPurpose={stayInformations.travelPurpose}
        onChange={(
          newGuests: {
            nbAdults: number;
            nbChildren: number;
            nbBabies: number;
          },
          newTravelPurpose: TravelPurpose,
        ) => {
          setStayInformations({
            guests: newGuests,
            travelPurpose: newTravelPurpose,
            haveChange: true,
          });
        }}
      />
    </Menu>
  );
};

export default GuestsMenuWithTextualTrigger;
